<template>
  <div class="container-fluid mt-3">
    <v-row>
      <v-progress-linear
        v-if="b_Loading"
        indeterminate
        color="primary"
      />
    </v-row>
    <v-row v-if="b_DeadlineExpired && b_AvailabilityConsulted">
      <v-col>
        <v-alert
          border="bottom"
          elevation="2"
        >
          {{ $t('general.the_deadline_for_entering_availabilities_has_expired') }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters class="ml-2">
      <v-col>
        <v-checkbox
          v-model="b_NotAvailableThisPeriod"
          :label="$t('Not available this BP')"
          color="danger"
          :disabled="b_DeadlineExpired"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="!b_NotAvailableThisPeriod">
        <v-chip
          v-for="(examDate, index) in a_ExamDate"
          :key="index"
          class="ma-2"
          :color="getColorChip(examDate.type)"
          text-color="white"
          @click="indexButton=index; setAvailability(examDate.id)"
          :disabled="b_DeadlineExpired || (b_LoadingButton && indexButton === index)"
        >
          <v-icon
            color="white"
            left
          >
            mdi-calendar
          </v-icon>
          {{ examDate.name }}
          <v-chip
            class="ml-2"
            x-small
            :text-color="getColorChip(examDate.type)"
            color="white"
          >
            {{ getDateFormat(examDate.examDate) }}
          </v-chip>
          <v-icon
            color="white"
            right
          >
            {{ getIconChip(examDate.type) }}
          </v-icon>
        </v-chip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { get, post } from '../../../worker/worker-api'
  import { mapState, mapGetters } from 'vuex'

  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Verfügbarkeit',
    },
    data () {
      return {
        a_ExamDate: [],
        a_Loading: [],
        b_Loading: false,
        b_LoadingButton: false,
        b_DeadlineExpired: true,
        b_NotAvailableThisPeriod: false,
        b_AvailabilityConsulted: false,
        indexButton: null,
      }
    },
    watch: {
      b_NotAvailableThisPeriod (val) {
        this.setGlobalAvailability()
      }
    },
    computed: {
      ...mapGetters(['getPlanningPeriodID', 'getCurrentExamEvent']),
      ...mapState([
      ]),
      allowedDates () {
        return this.a_AllowedDates
      },
    },
    methods: {
      getAvailability () {
        const events = []
        const teacherID = this.$smt.getAddressRoleID(this.$route.meta.role)
        this.b_Loading = true
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${teacherID}/period/${this.getCurrentExamEvent.i_PlanningPeriodID}/availability`, {
        })
          .then(response => {
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.b_DeadlineExpired = response.b_DeadlineExpired
            this.b_NotAvailableThisPeriod = response.b_NotAvailableThisPeriod
            let Ls_Color = this.colorEvent
            const La_Availability = response.Availability
            for (let i = 0; i < La_Availability.length; i++) {
              if (La_Availability[i].i_Type === 1) Ls_Color = 'light-green'
              if (La_Availability[i].i_Type === 2) Ls_Color = 'orange lighten-3'
              if (La_Availability[i].i_Type === 3) Ls_Color = 'orange'
              events.push({
                id: La_Availability[i].i_Id,
                name: La_Availability[i].s_Title,
                examDate: La_Availability[i].t_ExamDate,
                weekDate: La_Availability[i].t_WeekDate,
                timed: 0,
                color: Ls_Color,
                type: La_Availability[i].i_Type,
                available: La_Availability[i].b_Available,
                dayID: La_Availability[i].i_DayID,
                availabilityID: La_Availability[i].i_AvailabilityID,
                loading: false
              })
              this.a_Loading.push(false)
            }
            this.a_ExamDate = events.sort((a, b) => a.examDate > b.examDate ? 1 : -1)
            this.b_Loading = false
            this.b_AvailabilityConsulted = true
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      setGlobalAvailability () {
        this.a_ExamDate.forEach(exam => {
          exam.available = false
          exam.type = 3
        });
        const teacherID = this.$smt.getAddressRoleID(this.$route.meta.role)
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/${teacherID}/period/${this.getPlanningPeriodID}/global-availability`, {
          b_NotAvailableThisPeriod: this.b_NotAvailableThisPeriod,
        })
          .then(response => {
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      setAvailability (Ai_Id) {
        this.b_LoadingButton = true
        const La_SelectedExamDate = this.a_ExamDate.filter(examDate => examDate.id === Ai_Id)
        let Lo_SelectedExamDate
        (La_SelectedExamDate.length > 0)
          ? Lo_SelectedExamDate = La_SelectedExamDate[0]
          : Lo_SelectedExamDate = null
        if (Lo_SelectedExamDate === null) return
        const teacherID = this.$smt.getAddressRoleID(this.$route.meta.role)
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/${teacherID}/period/${this.getPlanningPeriodID}/availability`, {
          i_AvailabilityTypeID: this.getNextState(Lo_SelectedExamDate.type),
          i_DayID: Lo_SelectedExamDate.dayID,
          i_AvailabilityID: Lo_SelectedExamDate.availabilityID,
          t_WeekDate: Lo_SelectedExamDate.weekDate.substr(0, 10),
          i_EventID: Lo_SelectedExamDate.id
        })
          .then(response => {
            this.b_LoadingButton = false
            this.editMode = false
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.setNextState(Lo_SelectedExamDate)
            this.requestSent = true
            this.responseMessage = 'Verfügbarkeit gesendet'
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getAvailabilityID (Aa_Availability, As_Day) {
        const Li_DayID = this.getDayID(As_Day);
        const La_Availability = Aa_Availability.filter(availability => availability.i_DayID === Li_DayID);
        if(La_Availability.length > 0) return La_Availability[0].i_AvailabilityID;
        else return null;
      },
      getDayID(As_Day) {
        switch (As_Day) {
          case 'monday':
            return 1;
          case 'tuesday':
            return 2;
          case 'wednesday':
            return 3;
          case 'thursday':
            return 4;
          case 'friday':
            return 5;
          default:
            return null;
        }
      },
      getNextState (Ai_Type) {
        if(Ai_Type === 1) return 3
        //if(Ai_Type === 2) return 3
        if(Ai_Type === 3) return 1
        return 3
      },
      setNextState (Ao_SelectedExamDate) {
        const Li_IndexExamDate = this.a_ExamDate.findIndex(examDate => examDate.id === Ao_SelectedExamDate.id)
        const Li_NextState = this.getNextState(Ao_SelectedExamDate.type)
        if (Li_IndexExamDate === -1) this.a_ExamDate[Li_IndexExamDate].type = 3
        else this.a_ExamDate[Li_IndexExamDate].type = Li_NextState
      },
      getColorChip (Ai_Type) {
        switch (Ai_Type) {
          case 1:
            return 'success'
          case 2:
            return 'orange'
          case 3:
            return 'danger'
          default:
            return 'danger'
        }
      },
      getIconChip (Ai_Type) {
        switch (Ai_Type) {
          case 1:
            return 'mdi-account-check'

          case 2:
            return 'mdi-account-alert'

          case 3:
            return 'mdi-account-cancel'

          default:
            return 'mdi-account-cancel'
        }
      },
      getLabelChip(Ai_State) {
        switch (Ai_State) {
          case 1:
            return 'Verfügbar';

          case 2:
            return 'Wahrscheinlich';

          case 3:
            return 'nicht Verfügbar';

          default:
            return 'nicht Verfügbar';
        }
      },
      getEventColor (event) {
          return event.color
      },
      showIntervalLabel (interval) {
          return interval.minute === 0
      },
      getDateFormat (As_Date) {
        let Lo_Date = new Date(As_Date);
        let Ls_DateCHFormat = Lo_Date.getDate() + '.' + (parseInt(Lo_Date.getMonth()) + 1) + '.' + Lo_Date.getFullYear();
        return Ls_DateCHFormat
      },
    },
    created () {
      this.getAvailability()
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>